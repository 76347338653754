<template>
  <div id="unggas-nasional-data-distribusi-send-doc" data-app>
      
      <!--begin::Card-->
      <div class="card card-custom mt-8">

        <!--begin::Header-->
        <div class="card-header py-3">
          <div class="card-title">
            <button 
                @click="$router.push(`/unggas-nasional/distribusi/${idH}`)" 
                type="button" 
                class="btn btn-link-primary font-weight-bolder mr-5 font-size-lg"
                v-b-tooltip.hover title="List Distribusi DOC"
            >
                <div class="d-flex">
                    <span class="svg-icon svg-icon-lg">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/Navigation/Angle-double-left.svg" />
                        <!--end::Svg Icon-->
                    </span>
                    <span class="d-none d-sm-block">Kembali</span>
                </div>
            </button>
            <div class="align-items-start flex-column">
				        <h3 class="card-label font-weight-bolder text-dark">Distribusi DOC</h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1">Tambah data distribusi DOC disini</span>
            </div>
          </div>
        </div>
        <!--end::Header-->
        
        <div class="card-body">
          <component v-if="curComponent" :sdata="sdataForm" v-bind:is="curComponent"></component>        
        </div>
      </div>
      
  </div>

</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import FormPerusahaan from "@/view/pages/nasional/distribusi/form/send-doc/_Perusahaan.vue";
import FormPeternak from "@/view/pages/nasional/distribusi/form/send-doc/_Peternak.vue";

export default {
  name: "unggas-nasional-data-distribusi-send-doc",
  props: {
    idH: {
      required: true
    }
  },
  data() {
    return {
      loading: true,
    }
  }, 
  components: {
    ContentLoader,
    FormPerusahaan,
    FormPeternak,
  },    
  computed: {    
    ...mapGetters(["currentUser"]),
    curComponent() {
      if (!this.currentUser.akses) {
        return null
      }
      if (this.currentUser.akses.level <= 3) {
        return 'FormPerusahaan'
      }
      if (this.currentUser.akses.level > 3) {
        return 'FormPeternak'
      }
      return null
    },
    sdataForm() {
      return {
        _id: this.idH,
        action: 'add'
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Distribusi DOC" }]);
    if (!this.currentUser.menu.nasional_distribusi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    if(!this.currentUser.akses) {
      return this.$router.push({ name: 'auth-select-company', query: { rget: this.$route.path }}).catch(()=>{})
    }
  },
};
</script>